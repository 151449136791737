<template>
  <section>
    <v-container class="container-custom">
      <v-row>
        <v-col md="12">
          <h1 class="systemTitle">Add Page</h1>
          <v-form ref="investmentForm">
            <v-label>Page</v-label>
            <v-text-field
              v-model="pageName"
              outlined
              placeholder="Page"
              class="mt-1"
            ></v-text-field>
            <v-label>Description</v-label>
            <vue-editor
              class="inputbox mb-5 mt-1"
              v-model="description"
              placeholder="Description"
            ></vue-editor>
            <v-btn @click="validate" v-if="!loading" large>Add Page</v-btn>
            <v-btn v-if="loading" large loading></v-btn>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { VueEditor } from "vue2-editor";
export default {
  name: "AddPage",
  data: () => ({
    myCroppa: {},
    pageName: undefined,
    description:undefined,
    loading: false,
  }),
  components:{
    VueEditor
  },
  methods: {
    ...mapActions(["postPage", "getSinglePage"]),
    async validate() {
      this.loading = true;
      let data = {};
      if (this.pageName != undefined) {
        data.pageName = this.pageName;
      }
      if (this.description != undefined) {
        data.description = this.description;
      }
      if (this.$route.params.id != undefined) {
        data._id = this.$route.params.id;
      }
      await this.postPage(data);
      if (this.allPage.status == 200 || this.allPage.status == 201) {
        this.$router.push("/business/pages");
      }
      this.loading = false;
    },
  },
  computed: {
    ...mapGetters(["allPage"]),
  },
  async created() {
    if (this.$route.params.id) {
      await this.getSinglePage(this.$route.params.id);
      this.pageName = this.allPage.results.pageName;
      this.description = this.allPage.results.description;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.imageBox {
  img {
    border: 1px solid #efefef;
    width: 200px;
    height: 200px;
    object-fit: contain;
    border-radius: 10px;
    margin-right: 10px;
  }
}
</style>